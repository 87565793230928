import { useEffect, useRef, useState } from 'react'
import AppLayout from 'layouts/AppLayout'
import CardsCard from './components/CardsCard'
import classNames from 'classnames'
import { useCards } from 'hooks/useCards'
import { navigate } from 'gatsby'

import ArrowBack from '../../../../static/images/icons/arrow-back.svg'
import ArrowForward from '../../../../static/images/icons/arrow-forward.svg'

import './CardSelection.scss'
import makeTrackings from 'utils/make/trackings'
import { cardsFormId } from 'utils/make/constants'
import getDevice from 'utils/envs/getDevice'

const CardSelection = () => {
  const { setSelectedCard } = useCards()

  const [activeCard, setActiveCard] = useState(0)

  const slidesRef = useRef(null)

  const isDesktop = getDevice.isDesktop()

  const lateralScroll = isDesktop ? 342 : 296

  useEffect(() => {
    makeTrackings.CardsElementViewed(activeCard)
  }, [activeCard])

  useEffect(() => {
    slidesRef.current.isByButton = false
    slidesRef.current.onscroll = handleSlideScroll
  }, [])

  const handleSlideScroll = (e) => {
    if (!slidesRef?.current) return

    const scrollLeft = Math.round(e.target.scrollLeft)
    const scrollWidth = Math.round(e.target.scrollWidth)

    // //* INFO: 312 = 296 (card fixed width) + 16 (card right margin)
    const cardWidthPlusMargin = lateralScroll + 16

    if (!e.target.isByButton) {
      if (scrollLeft >= scrollWidth - window.innerWidth) {
        setActiveCard(2)
      } else if (scrollLeft >= cardWidthPlusMargin) {
        setActiveCard(1)
      } else {
        setActiveCard(0)
      }
    }
  }

  const handleBackCard = () => {
    if (activeCard === 0) return

    const newState = activeCard - 1

    setActiveCard((state) => state - 1)

    slidesRef.current.isByButton = true

    slidesRef.current.scroll({
      behavior: 'smooth',
      left: newState * lateralScroll,
    })

    setTimeout(() => {
      slidesRef.current.isByButton = false
    }, 500)
  }

  const handleForwardCard = () => {
    if (activeCard === 2) return

    const newState = activeCard + 1

    setActiveCard((state) => state + 1)

    slidesRef.current.isByButton = true

    slidesRef.current.scroll({
      behavior: 'smooth',
      left: newState * lateralScroll,
    })

    setTimeout(() => {
      slidesRef.current.isByButton = false
    }, 500)
  }

  const handleCardFieldName = (id) => {
    switch (id) {
      case 'rvbr-card':
        return 'cartao-rvbr'
      case 'rvbr-cashback':
        return 'cartao-rvbr-cashback'
      case 'rvbr-pass':
        return 'cartao-rvbr-pass-black'
      default:
        return 'cartao-rvbr'
    }
  }

  const handleSelectedCard = (id) => {
    makeTrackings.formContinued({
      formId: cardsFormId,
      formName: 'cards',
      stepId: 'd799598e-2e7b-42f8-91dc-888138ed36b6',
      stepName: 'cards-subproducts',
      stepNumber: 2,
      field: [{ fieldName: handleCardFieldName(id) }],
    })

    setSelectedCard(id)
    navigate('/cartoes/endereco')
  }

  return (
    <AppLayout
      contentHeaderInfos={{
        title: 'Conheça nossos cartões',
        description:
          'Disponibilizamos as melhores opções para você de acordo com o seu perfil.',
        onTrack: () => {
          makeTrackings.formBacked({
            formId: cardsFormId,
            formName: 'cards',
            stepId: 'b473f953-8b5b-440e-8b32-d917ec80c47a',
            stepName: 'cards-subproducts',
            stepNumber: 2,
          })
        },
      }}
      contentClassName="card-selection"
      layoutClassName="cards-layout"
    >
      <div className="card-selection__carousel-container">
        <div className="card-selection__carousel-content" ref={slidesRef}>
          <CardsCard
            id="rvbr-card"
            title="Cartão RVBR"
            description="Cartão de crédito sem anuidade com limite pré aprovado no cartão. sujeito a análise de crédito."
            image="/images/cards-rvbr-card.png"
            onClick={() => {
              handleSelectedCard('rvbr-card')
            }}
          />
          <CardsCard
            id="rvbr-cashback"
            title="Cartão RVBR Cashback"
            description="Cartão sem anuidade. Cashback de 1% e rendimento maior que poupança na conta digital."
            image="/images/cards-rvbr-cashback.png"
            onClick={() => {
              handleSelectedCard('rvbr-cashback')
            }}
          />
          <CardsCard
            id="rvbr-pass"
            title="Cartão RVBR Pass Black"
            description={
              <span>
                Acumule pontos e tenha anuidade grátis no cartão de crédito.
                <br />1 US$ = 2,2 pts.
              </span>
            }
            image="/images/cards-rvbr-pass.png"
            onClick={() => {
              handleSelectedCard('rvbr-pass')
            }}
          />
        </div>

        <div className="carousel-controls">
          <div className="carousel-controls__buttons">
            <button
              className="carousel-controls__control"
              disabled={activeCard === 0}
              onClick={handleBackCard}
            >
              <ArrowBack />
            </button>
            <button
              className="carousel-controls__control"
              disabled={activeCard === 2}
              onClick={handleForwardCard}
            >
              <ArrowForward />
            </button>
          </div>

          <div
            className={classNames(
              'carousel-controls__stepper',
              `carousel-controls__stepper--${activeCard + 1}`
            )}
          />
        </div>
      </div>
    </AppLayout>
  )
}

export default CardSelection
